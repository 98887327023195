import { useWidgetSettings } from "../../../contexts/WidgetSettingsContext";
import { Container, MerchantLocation } from "./style";

export function LocationList() {
    const {widgetSettings, setWidgetId} = useWidgetSettings()
    return (
        <Container>
            <span className="widget-title">Message us on...</span>
            {
                widgetSettings?.locationSettings.map((location) => {
                    return (
                        <MerchantLocation {...widgetSettings?.customStyles} onClick={() => setWidgetId(location.widgetId)}>{location.locationName}</MerchantLocation>
                    )
                })
            }
        </Container>
    )
}
