import { createContext, ReactNode, useContext, useState } from "react";
import WidgetService, { ChannelDomain, ChannelTypes, WidgetCustomStyles } from "../services/channels";

export type WidgetLocationSettings = {
    widgetId: string;
    channelByType: Map<ChannelTypes, ChannelDomain>;
    externalContactFormUrl?: string;
    locationName: string;
    merchantPublicId: string;
    merchantCountry?: string;
}

export type WidgetSettings = {
    logo: string;
    locationSettings: WidgetLocationSettings[];
    customStyles?: WidgetCustomStyles;
    hidePoweredBy?: boolean;
    preventAutoOpen?: boolean;
    delayAutoOpen?: number;
    buttonTitle?: string;
    successTitle?: string;
    hideContactForm?: boolean;
    successSubtitle?: string;
    size?: "sm" | "md" | "lg";
    buttonImageUrl?: string;
}

type WidgetSettingsContextProps = {
    widgetId?: string;
    isLoaded?: boolean;
    errorOnLoading?: boolean
    widgetSettings?: WidgetSettings;
    loadSettings: (widgetIds: string[]) => void;
    setWidgetId: (widgetId: string) => void;
}

const WidgetSettingsContext = createContext({} as WidgetSettingsContextProps)

type WidgetSettingsContextProviderProps = {
    children: ReactNode
}

export const WidgetSettingsContextProvider = ({children}: WidgetSettingsContextProviderProps) => {

    const [settings, setSettings] = useState<WidgetSettings | undefined>(undefined);
    const [widgetId, setWidgetId] = useState("");
    const [errorOnLoading, setErrorOnLoading] = useState(false);

    const loadSettings = async (widgetIds: string[]) => {
        if (!settings) {
            let index;
            let sett = {locationSettings: [] as WidgetLocationSettings[]} as WidgetSettings

            for (index = 0; index < widgetIds.length; index++) {
                const currentMerchantId = widgetIds[index];
                const widget = await WidgetService.get(currentMerchantId);

                if (widget) {
                    const channelByType = widget?.channels.reduce((channels, currentChannel) => {
                        channels.set(currentChannel.type, currentChannel);
                        return channels;
                    }, new Map<ChannelTypes, ChannelDomain>())

                    sett = {
                        logo: widget.logo,
                        locationSettings: [...sett.locationSettings, {
                            merchantPublicId: widget.merchantPublicId,
                            merchantCountry: widget.merchantCountry,
                            widgetId: currentMerchantId,
                            locationName: widget.locationName,
                            channelByType, externalContactFormUrl: widget.externalContactFormUrl
                        }],
                        customStyles: widget.customStyles,
                        size: widget.size,
                        hidePoweredBy: widget.hidePoweredBy,
                        preventAutoOpen: widget.preventAutoOpen,
                        delayAutoOpen: widget.delayAutoOpen,
                        buttonTitle: widget.buttonTitle,
                        successTitle: widget.successTitle,
                        successSubtitle: widget.successSubtitle,
                        hideContactForm: widget.hideContactForm,
                        buttonImageUrl: widget.buttonImageUrl
                    };
                } else {
                    setErrorOnLoading(true);
                }
            }

            setSettings(sett);
            if (sett.locationSettings.length === 1) {
                setWidgetId(sett.locationSettings[0].widgetId);
            }
        }
    }

    return (
        <WidgetSettingsContext.Provider value={{
            widgetId: widgetId,
            widgetSettings: settings,
            isLoaded: !!settings,
            errorOnLoading,
            loadSettings,
            setWidgetId
            }}>
            {children}
        </WidgetSettingsContext.Provider>
    )
}

export const useWidgetSettings = () => useContext(WidgetSettingsContext);

